<template>
	<div class="app-container">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('products.family.details')}} #{{familyId}}</span>
				<span style="float: right">
					<el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="saveProductsFamily">
						{{$t('products.family.save')}}</el-button>
				</span>
			</div>
			<div>
				<div class="sub-title">{{$t('products.family.family-id')}}</div>
				<el-input placeholder="Ex: 123" :disabled="true" v-model="familyDetails.family_id"></el-input>
			</div>
			<div class="separator"></div>
			<div>
				<div class="sub-title">{{$t('products.family.family-name')}}</div>
				<el-input placeholder="Ex: Pantofi sport Puma pentru barbati" v-model="familyDetails.family_name">
				</el-input>
			</div>
			<div class="separator"></div>
			<div>
				<div class="sub-title">{{$t('products.family.characts')}}
					<el-button type="primary" icon="el-icon-plus" size="mini" @click="addFamilyCharact" plain>
					</el-button>
				</div>
				<div>
					<c-select @selected="categorySelected" :categ="familyDetails.categ_id"></c-select>
				</div>
				<div class="separator"></div>
				<el-card shadow="never" class="product-family-card" v-for="(item, i) in familyDetails.characteristics"
					:key="i">
					<div slot="header" class="clearfix">
						<span class="sub-title">{{$t('products.family.type')}} #{{i + 1}}</span>
						<el-button style="float:right" size="small" type="text" @click="removeFamilyCharact(i)">
							{{$t('general.delete')}}</el-button>
					</div>
					<div>
						<div class="sub-title">{{$t('products.family.family-type')}}</div>
						<el-select size="small" filterable v-model="familyDetails.characteristics[i].charact"
							:placeholder="$t('products.family.family-type')"
							:no-data-text="$t('products.family.no-data-text')" class="fullWidth">
							<el-option v-for="item in familyTypeOptions" :label="item.label" :value="item.value"
								:key="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="separator"></div>
					<div>
						<div class="sub-title">{{$t('products.family.family-mode')}}</div>
						<el-select size="small" v-model="familyDetails.characteristics[i].mode"
							:placeholder="$t('products.family.family-mode')" class="fullWidth">
							<el-option v-for="item in familyModeOptions" :label="item.label" :value="item.value"
								:key="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="separator"></div>
				</el-card>
			</div>
			<div class="separator"></div>
			<el-button type="primary" class="fullWidth" size="small" icon="el-icon-check" plain
				@click.native="saveProductsFamily">{{$t('products.family.save')}}</el-button>
		</el-card>
		<div class="separator"></div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('products.family.product-list')}}</span>
			</div>
			<div class="center">
				<el-pagination layout="prev, pager, next, total" :current-page="currentPage" :total="count"
					:page-size="limit" @current-change="changePage">
				</el-pagination>
			</div>
			<el-table :data="familyProducts" border style="width: 100%" :empty-text="$t('products.family.empty-text')">
				<el-table-column prop="products_name" :label="$t('products.family.name')">
					<template slot-scope="scope">
						<a :href="scope.row.url" target="_blank">{{scope.row.products_name}}</a>
					</template>
				</el-table-column>
				<el-table-column prop="products_model" :label="$t('products.family.product-model')" width="180px">
				</el-table-column>
				<el-table-column :label="$t('general.action')" width="170px" height="100px">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" icon="el-icon-delete" plain
							@click="removeProductFromFamily(scope.row.products_model)">
							{{$t('products.family.remove-from-family')}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="center">
				<el-pagination layout="prev, pager, next, total" :current-page="currentPage" :total="count"
					:page-size="limit" @current-change="changePage">
				</el-pagination>
			</div>
		</el-card>
		<div class="separator"></div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('products.family.add-products-to-family')}}</span>
			</div>
			<div>
				<div class="sub-title">{{$t('products.family.search-product')}}</div>
				<el-input v-model="sProducts.opts.search" @keypress.native.enter="getSupplierProducts(true)">
					<el-button slot="append" icon="el-icon-search" @click="getSupplierProducts(true)"></el-button>
				</el-input>
			</div>
			<div class="separator"></div>
			<el-table :data="sProducts.list" border style="width: 100%"
				:empty-text="$t('products.family.empty-text-2')">
				<el-table-column prop="products_name" :label="$t('products.family.name')">
					<template slot-scope="scope">
						<a :href="'http://' + scope.row.link" target="_blank">{{scope.row.products_name}}</a>
					</template>
				</el-table-column>
				<el-table-column prop="products_model" :label="$t('products.family.product-model')" width="180px">
				</el-table-column>
				<el-table-column :label="$t('general.actions')" width="170px" height="100px">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" icon="el-icon-plus" plain
							@click="addProductToFamily(scope.row.products_model)">
							{{$t('products.family.add-product-to-family')}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="center" v-if="moreProductsAvailable">
				<div class="separator"></div>
				<el-button class="center" type="primary" icon="el-icon-more" size="medium" plain
					@click.native="loadMore">{{$t('products.family.show-more')}}</el-button>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		getFamilyDetails,
		addProductsFamily,
		getProductsForFamily,
		removeProductFromFamily,
		addProductToFamily,
		getProducts
	} from '@/api/produse';
	import {
		debugPromise
	}
	from '@/utils/index';
	const CategorySelect = () =>
		import('@/components/CategoriesSelect');
	import {
		getCategCharacts
	} from '@/api/publicare';

	export default {
		components: {
			'c-select': CategorySelect
		},
		props: ['famId'],
		data() {
			return {
				familyId: 0,
				familyDetails: {},
				familyProducts: [],
				family: {
					name: '',
					id: '',
					characts: [{
						charact: '',
						mode: 1
					}],
					categ_id: 0
				},
				familyModeOptions: [{
						value: 1,
						label: 'Text'
					},
					{
						value: 2,
						label: this.$t('products.family.image')
					}
				],
				allCharacteristics: [],
				categories: [],
				category: [],
				start: 0,
				limit: 15,
				pages: 0,
				ccpage: 0,
				count: 0,
				currentPage: 1,
				sProducts: {
					list: [],
					opts: {
						start: 0,
						limit: 10,
						mavab: false,
						search: '',
						excludeFamily: 1
					}
				}
			};
		},
		methods: {
			changePage(page) {
				this.start = this.limit * (page - 1);
				this.currentPage = page;
				this.getProductsForFamily();
			},
			getFamilyDetails() {
				getFamilyDetails({
					id: this.familyId
				}).then((res) => {
					if (typeof res.message !== 'undefined') {
						this.allCharacteristics = [];
						this.familyDetails = res.message;
						this.familyDetails.characteristics.forEach((item) => {
							this.allCharacteristics.push({
								charact_id: item.charact,
								charact_name: item.charactName
							});
						});
						this.getProductsForFamily();
					}
				});
			},
			getProductsForFamily() {
				getProductsForFamily({
					id: this.familyId
				}).then((res) => {
					if (typeof res.message === 'undefined') return false;
					this.familyProducts = res.message;
				});
			},
			addFamilyCharact() {
				this.familyDetails.characteristics.push({
					'charact': '',
					'mode': 1
				});
			},
			removeFamilyCharact(index) {
				this.familyDetails.characteristics.splice(index, 1);
			},
			categorySelected(categ) {
				if (typeof categ.categories_id !== 'undefined' && parseInt(categ.categories_id) !== 0) {
					this.familyDetails.categ_id = categ.categories_id;
					getCategCharacts({
						categ_id: categ.categories_id
					}).then((res) => {
						if (typeof res.message === 'undefined') return false;
						this.allCharacteristics = Object.values(res.message).sort((a, b) => {
							return b.priority - a.priority;
						});
					}).catch((e) => {
						this.$reportToSentry(e, {
							extra: {
								fn: 'getCategCharacts',
								params: {
									categ_id: categ.categories_id
								}
							}
						});
						debugPromise('getCategCharacts', e);
					});
				} else {
					// this.resetFields();
				}
			},
			saveProductsFamily() {
				if (!this.validateData()) return false;
				const characts = this.familyDetails.characteristics.map((item) => {
					return {
						mode: item.mode,
						charact: item.charact
					};
				});
				const params = {
					name: this.familyDetails.family_name,
					id: this.familyDetails.family_id,
					characts: characts,
					categ_id: this.familyDetails.categ_id
				};
				addProductsFamily(params).then((res) => {
					if (typeof res.message.success !== 'undefined' && parseInt(res.message.success) === 0) {
						this.sbMsg({
							type: 'warn',
							message: this.$t('products.family.already-exists')
						});
					} else {
						this.getFamilyDetails();
					}
				});
				return true;
			},
			validateData() {
				if (this.familyDetails.family_id === '') {
					this.sbMsg({
						type: 'warn',
						message: this.$t('products.family.required-id')
					});
					return false;
				}
				if (this.familyDetails.family_name === '') {
					this.sbMsg({
						type: 'warn',
						message: this.$t('products.family.required-name')
					});
					return false;
				}
				if (this.familyDetails.characteristics.length < 1) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('products.family.required-charact')
					});
					return false;
				}
				return true;
			},
			removeProductFromFamily(pmodel) {
				this.$confirm(this.$t('products.family.confirm-remove'), this.$t('general.warning'), {
						confirmButtonText: this.$t('general.yes'),
						cancelButtonText: this.$t('general.no'),
						type: 'warning'
					})
					.then(() => {
						removeProductFromFamily({
							model: pmodel
						}).then((res) => {
							if (typeof res.message.success !== 'undefined' && parseInt(res.message.success) ===
								1) {
								this.getProductsForFamily();
								this.getSupplierProducts();
							} else {
								this.sbMsg({
									type: 'warn',
									message: this.$t('products.family.error-exclude')
								});
							}
							return true;
						});
					})
					.catch((e) => {
						this.$reportToSentry(e, {
							extra: {
								fn: 'removeProductFromFamily',
								params: {
									model: pmodel
								}
							}
						});
						debugPromise('removeProductFromFamily', e);
					});
			},
			getSupplierProducts(withSearch = false) {
				getProducts(this.sProducts.opts).then((res) => {
					if (typeof res.message === 'undefined' || !res.message.data) {
						this.sProducts.opts.mavab = false;
						if (withSearch) {
							this.sProducts.list = []; // reset the list if search is used and no products found
						}
						return false;
					}
					if (withSearch) {
						this.sProducts.list = res.message.data;
					} else {
						this.sProducts.list = [...this.sProducts.list, ...res.message.data];
					}
					this.sProducts.opts.mavab = res.message.moreavailable;
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'getSupplierProducts',
							params: {
								model: this.sProducts.opts
							}
						}
					});
					debugPromise('getProducts', e);
				});
			},
			addProductToFamily(model) {
				this.$confirm(this.$t('products.family.confirm-add'), this.$t('general.warning'), {
						confirmButtonText: this.$t('general.yes'),
						cancelButtonText: this.$t('general.no'),
						type: 'warning'
					})
					.then(() => {
						addProductToFamily({
								model,
								familyId: this.familyId
							})
							.then((res) => {
								if (typeof res.message.success !== 'undefined' && parseInt(res.message.success) ===
									1) {
									this.getProductsForFamily();
								} else {
									this.sbMsg({
										type: 'warn',
										message: this.$t('products.family.product-already-exists')
									});
								}
								return true;
							})
							.catch((e) => {
								this.$reportToSentry(e, {
									extra: {
										fn: 'addProductToFamily',
										params: {
											model,
											familyId: this.familyId
										}
									}
								});
							});
					})
					.catch((e) => {
						debugPromise('addProductToFamily', e);
					});
			},
			loadMore() {
				this.sProducts.opts.start += 15;
			}
		},
		computed: {
			familyTypeOptions() {
				if (this.allCharacteristics.length < 1) return [];
				return this.allCharacteristics.filter((item) => {
					return typeof item.charact_id !== 'undefined' && item.charact_id !== '' && typeof item
						.charact_name !==
						'undefined' && item.charact_name !== '';
				}).map((item) => {
					return {
						value: item.charact_id,
						label: item.charact_name
					};
				});
			},
			moreProductsAvailable() {
				return this.sProducts.opts.mavab;
			}
		},
		watch: {
			'sProducts.opts.start'() {
				this.getSupplierProducts();
			}
		},
		beforeMount() {
			try {
				this.familyId = this.famId;
			} catch (e) {
				this.sbMsg({
					type: 'warn',
					message: this.$t('products.family.invalid-model')
				});
				this.$router.push({
					name: 'Familii produse'
				}).catch(() => {});
				return false;
			}
			if (this.familyId === '') {
				this.sbMsg({
					type: 'warn',
					message: this.$t('products.family.invalid-family'),
				});
				this.$router.push('/produse/familii').catch(() => {});
				return false;
			}
			this.getFamilyDetails();
			this.getSupplierProducts();
		}
	};
</script>
<style rel="stylesheet/scss" lang="scss">
	@import './src/styles/modules/produse.scss'
</style>